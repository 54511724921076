import React from 'react'

import Banner from '../images/stadium-banner.png'
import Image from '../images/stadium-img.png'

import { Contact } from '../components/Contact'
import { OtherServices } from '../components/OtherServices'
import { Pricing } from '../components/Pricing'

export const Stadium = () => {
  return (
    <>
        <div className='bg-pengamum-black py-16'>
            <div className='grid md:grid-cols-2 max-w-[1440px] mx-auto gap-4 items-center px-4'>
                <div className='max-w-[500px] mx-auto md:text-left text-center'>
                    <p className='text-5xl text-white font-bold'>Stadyum Reklamları</p>
                    <p className='text-white py-4'>Markanızın adını tribünlere ve yeşil sahalara taşıyın! Pengamum olarak partnerlerimizin mesajlarını doğru hedef kitlelere ulaştırmanın memnuniyetini yaşıyoruz.</p>
                    <a href="/contact-us"><div className='bg-pengamum-green hover:bg-[#297d3f] text-white hover:text-pengamum-green font-semibold p-2 text-center max-w-36 md:mx-0 mx-auto rounded-3xl'>
                        <p className='inline-block'>Teklif Al</p>
                    </div></a>
                </div>
                <div>
                    <img src={Banner} alt="" />
                </div>
            </div>  
            
            <div className='grid md:grid-cols-2 gap-6 max-w-[1440px] mx-auto items-center md:text-left py-10 text-center'>
                <div>
                    <img className='mx-auto' src={Image} alt="" />
                </div>
                <div className='max-w-[700px]'>
                    <p className='text-4xl text-white font-bold pb-4'>Stad Reklam Panoları</p>
                    <p className='text-white'>
                    Stadyumlar, binlerce hatta milyonlarca insanın bir araya geldiği yerlerdir. Reklamlarınız, bu büyük kitlelere canlı ve etkili bir şekilde ulaşarak marka bilinirliğinizi artırır. ev ekranlar, pankartlar, ve diğer reklam alanları sayesinde stadyumlar, görsel etkileşimi maksimize eder. Markanızın dikkat çekici ve unutulmaz bir şekilde sergilenmesini sağlar. Stadyum reklamcılığı, geleneksel reklamcılıktan sıyrılarak yaratıcı ve yenilikçi fırsatlar sunar. Özel etkinlikler, interaktif reklamlar ve diğer özel projelerle markanızı öne çıkarabilirsiniz. Acele edin markanızın değerini katlamak için Pengamum’la iletişime geçin!
                    </p>
                </div>
            </div>
        </div>

        <Pricing/>
        <OtherServices/>
        <Contact/>
    </>
  )
}
