import { useState } from 'react'
import { useForm } from "react-hook-form"
import axios from 'axios'
import Popup from 'reactjs-popup';

const ContactForm2 = () => {
    const [open, setOpen] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset
    } = useForm()

    const onSubmit = async (data) => {
        console.log(data)
        try {
            setOpen(true)
            const result = await axios.post('https://btbas1qhnk.execute-api.eu-central-1.amazonaws.com/v1/form', {
                subject: 'Contact Form',
                data: data
            });
            reset()
            setTimeout(() => {
                setOpen(false)
            }, 2500);
        } catch (error) {
            setTimeout(() => {
                setOpen(false)
            }, 2500);
            console.log(error)
        }
    }

    return (
        <div className='grid grid-cols-2 gap-4 max-w-[700px]'>
            <input
                {...register("firstname", { required: true })}
                class="shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                placeholder="İsim" />
            <input
                {...register("lastname", { required: true })}
                class="shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="surname"
                type="text"
                placeholder="Soyisim" />
            <input
                {...register("phone", { required: true })}
                class="shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="number"
                type="text"
                placeholder="Telefon" />
            <input
                {...register("email", { required: true })}
                class="shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                placeholder="Email" />
            <input class="shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="firm" type="text" placeholder="Firma/Website" />
            <select
                {...register("service", { required: true })}
                class="shadowborder rounded-lg w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="service"
                id="service">
                <option value="Stadyum Reklamları">Stadyum Reklamları</option>
                <option value="Canlı Spor Yayınları">Canlı Spor Yayınları</option>
                <option value="Otomatik Canlı Destek Hizmeti">Otomatik Canlı Destek Hizmeti</option>
                <option value="Dijital Pazarlama ve SEO">Dijital Pazarlama ve SEO</option>
                <option value="Mobil Uygulama">Mobil Uygulama</option>
            </select>
            <textarea
                {...register("message", { required: true })}
                class="col-span-2 shadow appearance-none border rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="message"
                type="text"
                rows={5}
                placeholder="Mesajınız..." />
            <button onClick={handleSubmit(onSubmit)} className='col-span-2' href="#">
                <div className='bg-pengamum-green hover:bg-[#297d3f] text-white hover:text-pengamum-green font-semibold p-2 text-center max-w-36 md:mx-0 mx-auto rounded-3xl'>
                    <p className='inline-block'>Gönder</p>
                </div>
            </button>
            <Popup open={open} modal contentStyle={{
                width: 'auto',
                padding: '0px',
                borderWidth: '0px',
                borderRadius: '9px',
            }}>
                <div className="relative w-full max-w-md h-full md:h-auto">
                    <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                        <button
                            onClick={() => setOpen(false)}
                            type="button"
                            className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                            <svg aria-hidden="true" className="w-8 h-8 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Success</span>
                        </div>
                        <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">Formunuz Başarı ile Gönderilmiştir!</p>
                    </div>
                </div>
            </Popup>
        </div>
    )
}

export default ContactForm2