import React from 'react'

import DrawBanner from '../images/draw-banner.png'
import DrawImage from '../images/draw-image.png'

import { Contact } from '../components/Contact'
import { OtherServices } from '../components/OtherServices'
import { Pricing } from '../components/Pricing'

import Tick from '../images/green-tick.svg'

export const Draw = () => {
  return (
    <>
        <div className='bg-pengamum-black py-16'>
            <div className='grid md:grid-cols-2 max-w-[1440px] mx-auto items-center px-4'>
            <div className='max-w-[600px] mx-auto md:text-left text-center'>
                <p className='text-5xl text-pengamum-green font-bold'>Çekiliş Sistemi</p>
                <p className='text-white py-4'>Pengamum Çekiliş Sistemi yazılımı ile müşterilerinizi ve satışlarınızı artıracak bir promosyon sistemine sahip olun. Farklı varyasyonlarda çekilişler düzenleyin, kampanyalarınıza ekleyin.</p>
                <a href="/contact-us"><div className='bg-pengamum-green hover:bg-[#297d3f] text-white hover:text-pengamum-green font-semibold p-2 text-center max-w-36 md:mx-0 mx-auto rounded-3xl'>
                    <p className='inline-block'>Teklif Al</p>
                </div></a>
            </div>
            <div>
                <img className='mx-auto' src={DrawBanner} alt="" />
            </div>
            </div>  

            <div className='grid md:grid-cols-2 gap-6 max-w-[1440px] mx-auto items-center md:text-left py-10 text-center'>
                <div>
                    <img className='mx-auto' src={DrawImage} alt="" />
                </div>
                <div className='max-w-[700px] mt-10'>
                    <p className='text-4xl text-white font-bold pb-4'>Gelir ve Etkileşimlerinizi Zirveye Taşımaya Hazır Mısınız?</p>
                    <p className='text-white'>
                    Çekiliş sistemi ile markanıza değer katabilir, hedef kitlenizi genişletebilir, kullanıcı ve müşterilerinizi artırabilir, sosyal medyada etkileşilmlerinizi artırabilirsiniz. Üstelik farklı varyasyon ve kriterlerde farklı çekilişler düzenleyebilirsiniz.
                    </p>
                    <a className='block' href="/contact-us"><div className='bg-pengamum-green hover:bg-[#297d3f] w-36 md:mx-0 mx-auto text-pengamum-black hover:text-white mt-4 font-semibold p-2 rounded-3xl pl-4'>
                        <p className='inline-block'>İletişime Geç ▸</p>
                    </div></a>
                </div>
            </div> 
        </div>

        <div className='bg-pengamum-black text-white py-16 md:text-left text-center'>
            <div className='max-w-[1440px] mx-auto grid md:grid-cols-2 items-center px-4'>
                <div className='max-w-[600px] mx-auto'>
                    <p className='text-pengamum-green font-bold py-2 text-lg'>ÖZELLİKLER</p>
                    <p className='font-bold text-2xl pb-2'>Çekiliş Sistemi ile Sağlayacağınız Avantajlar</p>
                    <p>Pengamum Çekiliş Sistemi yazılımı ile marka değerinden, karlılığa kadar birçok konuda avantaj elde edersiniz. Çekilişler size yeni kampanyalar oluşturma, kullanıcılarınızla etkileşim içerisinde olma ve marka sadakati sağlama konusunda eşsiz bir yere sahiptir.</p>
                </div>
                <div className='text-left pt-4 md:pl-0 pl-4'>
                    <div className='flex pb-3'>
                        <img className='pr-2' src={Tick} alt="" />
                        <p>Düzenlenebilir seçenek ve varyasyonlar</p>
                    </div>
                    <div className='flex pb-3'>
                        <img className='pr-2'  src={Tick} alt="" />
                        <p>Marka değerinizi artırma</p>
                    </div>
                    <div className='flex pb-3'>
                        <img className='pr-2'  src={Tick} alt="" />
                        <p>Marka sadakati oluşturma</p>
                    </div>
                    <div className='flex pb-3'>
                        <img className='pr-2'  src={Tick} alt="" />
                        <p>Satışları artırma</p>
                    </div>
                    <div className='flex pb-3'>
                        <img className='pr-2'  src={Tick} alt="" />
                        <p>Bilinirliği artırma, kampanya oluşturma</p>
                    </div>
                    <div className='flex pb-3'>
                        <img className='pr-2'  src={Tick} alt="" />
                        <p>Kullanıcı ya da müşteri sayısını artırma</p>
                    </div>
                    <div className='flex'>
                        <img className='pr-2'  src={Tick} alt="" />
                        <p>Sosyal medya ve web etkileşimlerini artırma</p>
                    </div>
                </div>
            </div>
        </div>
        <Pricing/>
        <OtherServices/>
        <Contact/>
    </>
  )
}
