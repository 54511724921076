import React from 'react'

import { Contact } from '../components/Contact'
import { OtherServices } from '../components/OtherServices'
import { Pricing } from '../components/Pricing'

import Banner from '../images/livesupport-banner.png'

import Tick from '../images/big-green-tick.svg'

import Handico from '../images/hand-ico.svg'
import Lockico from '../images/lock-ico.svg'
import Deviceico from '../images/device-ico.svg'
import Circleico from '../images/circle-ico.svg'
import Predictico from '../images/predict-ico.svg'
import Certico from '../images/cert-ico.svg'

export const LiveSupport = () => {
  return (
    <>
        <div className='bg-pengamum-black py-16'>
            <div className='grid md:grid-cols-2 gap-4 items-center max-w-[1440px] mx-auto px-4'>
            <div className='max-w-[600px] mx-auto md:text-left text-center'>
                <p className='text-5xl text-white font-bold'>Otomatik Canlı Destek</p>
                <p className='text-white py-4'>Günümüz iş dünyasında, başarılı bir dijital varlık oluşturmak ve online görünürlüğü artırmak işletmelerin sıradanlıktan sıyrılmasını sağlar. Pengamum olarak bu noktada profesyonel dijital pazarlama ve SEO hizmetlerimizle, markanızı dijital dünyada güçlendirmek ve rakiplerinizin önüne geçmek için buradayız.</p>
                <a href="/contact-us"><div className='bg-pengamum-green hover:bg-[#297d3f] text-white hover:text-pengamum-green font-semibold p-2 text-center max-w-36 md:mx-0 mx-auto rounded-3xl'>
                    <p className='inline-block'>Teklif Al</p>
                </div></a>
            </div>
            <div>
                <img src={Banner} alt="" />
            </div>
            </div>  
        </div>

        <div className='bg-pengamum-black py-16 px-4'>
            
            <div className='grid md:grid-cols-2 gap-6 max-w-[1440px] mx-auto items-center md:text-left text-center'>
                <div className='max-w-[700px]'>
                    <p className='text-4xl text-white font-bold pb-4'>Otomatik Canlı Destek Özellikleri</p>
                    <p className='text-white'>
                    Tam otomatik canlı destek hizmeti size düşük maliyet, kullanıcı memnuniyeti, zamandan tasarruf gibi birçok  avantaj sağlar.
                    </p>
                </div>
                <div>
                    <img className='mx-auto' src={Tick} alt="" />
                </div>
            </div>
            <div className='grid md:grid-cols-6 gap-4 grid-cols-2 max-w-[1440px] mx-auto py-12'>
                <div>
                    <img className='mx-auto' src={Handico} alt="" />
                </div>
                <div>
                    <img className='mx-auto' src={Lockico} alt="" />
                </div>
                <div>
                    <img className='mx-auto' src={Deviceico} alt="" />
                </div>
                <div>
                    <img className='mx-auto' src={Circleico} alt="" />
                </div>
                <div>
                    <img className='mx-auto' src={Predictico} alt="" />
                </div>
                <div>
                    <img className='mx-auto' src={Certico} alt="" />
                </div>
            </div>
            <a href="/"><div className='hover:bg-pengamum-green bg-white text-black hover:text-white font-semibold p-2 text-center max-w-48 mx-auto rounded-3xl'>
                    <p className='inline-block'>Demo Talep Et</p>
            </div></a>
        </div>

        <Pricing/>
        <OtherServices/>
        <Contact/>
    </>
  )
}
