import React from 'react'

import Card1 from '../images/seo-card1.png'
import Card2 from '../images/seo-card2.png'
import Card3 from '../images/seo-card3.png'

export const OtherServices = () => {
  return (
    <>
        <div>
            <div className='bg-pengamum-black md:h-[600px] text-white px-4'>
                <p className='text-center font-bold pt-8 text-3xl'>Diğer Hizmet ve Çözümler</p>
                <div className='grid md:grid-cols-3 gap-4 max-w-[1440px] mx-auto py-4 md:text-left text-center'>
                    <div>
                        <img className='md:mx-0 mx-auto' src={Card1} alt="" />
                        <p className='font-bold py-2'>Mobil Uygulama</p>
                        <p className='line-clamp-3'>Pengamum mobile özel uygulama ve çözümleri ile yanınızda! Referanslarımız arasında yer alan çeşitli sektörlerden müşterilerimiz mobil uygulama ve mobile yönelik çözümlerimizden fazlasıyla memnun. Size özel bir çözüm sunmak için buradayız.</p>
                        <a href=""><p className='pt-2 text-pengamum-green font-semibold'>Detaylar ➞</p></a>
                    </div>
                    <div>
                        <img className='md:mx-0 mx-auto' src={Card2} alt="" />
                        <p className='font-bold py-2'>Stadyum Reklamları</p>
                        <p className='line-clamp-3'>Markanızın adını tribünlere ve yeşil sahalara taşıyın! Pengamum olarak partnerlerimizin mesajlarını doğru hedef kitlelere ulaştırmanın memnuniyetini yaşıyoruz.</p>
                        <a href=""><p className='pt-2 text-pengamum-green font-semibold'>Detaylar ➞</p></a>
                    </div>
                    <div>
                        <img className='md:mx-0 mx-auto' src={Card3} alt="" />
                        <p className='font-bold py-2'>Canlı Spor Yayınları</p>
                        <p className='line-clamp-3'>Pengamum canlı spor yayınları platformu yazılımı, Uluslararası, Avrupa, Amerika, Afrika ve Asya bayi tabanlı operatörlere hizmet verir.</p>
                        <a href=""><p className='pt-2 text-pengamum-green font-semibold'>Detaylar ➞</p></a>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
