import React from 'react'

import BannerImage from '../images/about-banner.png'
import AboutImage from '../images/about-image.png'
import AboutImage2 from '../images/about-image-2.png'

import Xbet from '../images/1xbet.svg'
import Pinbahis from '../images/pinbahis.svg'
import Asyabahis from '../images/asyabahis.svg'
import Sekabet from '../images/sekabet.svg'
import Betnano from '../images/betnano.svg'
import Dumanbet from '../images/dumanbet.svg'
import Maltcasino from '../images/maltcasino.svg'
import Olabahis from '../images/olabahis.svg'

import { Contact } from '../components/Contact'

export const About = () => {
  return (
    <>
        <div className='bg-pengamum-black py-16'>
            <div className='grid md:grid-cols-2 max-w-[1440px] mx-auto gap-8 items-center'>
                <div className='max-w-[600px] mx-auto md:text-left text-center'>
                    <p className='text-pengamum-green fomt-semibold'>PENGAMUM NEDİR?</p>
                    <p className='text-5xl text-white font-bold'>Yazılım ve SEO Çözümleri</p>
                    <p className='text-white py-4'>Sektörünüz ne olursa olsun Pengamum ile çözüm var! Tahmin yazılımları, promosyon yazılımları, özelleştirilmiş yazılım çözümleri, SEO ve çok daha fazlasını Pengamum ile profesyonel düzeyde elde edin.</p>
                    <div className='bg-white hover:bg-[#297d3f] text-pengamum-black hover:text-white font-semibold p-2 text-center max-w-36 md:mx-0 mx-auto rounded-3xl'>
                        <p className='inline-block'>İletişime Geç</p>
                    </div>
                </div>
                <div>
                    <img src={BannerImage} alt="" />
                </div>
            </div>  
        </div>
        <div className={"container-full z-0 relative bg-no-repeat"} style={{ backgroundImage: `url("${AboutImage}")` }}>
            <div className='grid md:grid-cols-2 max-w-[1240px] mx-auto pt-28 items-center mx-4'>
                <div>
                    <img className='md:w-[400px] w-72 md:mx-0 mx-auto' src={AboutImage2} alt="" />
                </div>
                <div className='md:bg-pengamum-gray bg-pengamum-black'>
                    <p className='text-white py-4 mx-4'>Algoritmaların hakim olduğu bir dünyada, işletmelerini ve kariyerlerini optimize etmek ve büyütmek isteyen SEO uzmanları, pazarlamacılar ve girişimciler için zamanında ve ilgili bilgiler sunuyoruz.
                    <br/><br/>
                    Biz de sizin gibi SEO profesyonelleri, içerik yazarları ve pazarlamacılarıyız. Haber aramaları ve mobil tahmin uygulamaları konusunda lider olarak, 2003'te daha sınırlı hizmet veren bir platformdan bugün aylık 1.5M+ ziyaretçi getirebilen ve hizmet alanlarını çeşitlendirmiş bir platform haline geldik. Ve her gün işe gelme sebebimiz, sizlersiniz. İş ortaklarımızı kariyerlerinde lider hale getirmeyi ve onları dijital anlamda geliştirmeyi amaçlıyoruz.
                    <br/><br/>
                    Pengamum, tahmin yazılımları, mobil uygulama, SEO, stadyum reklamları ve canlı spor müsabakası yayıncılığı gibi alanlarda birçok köklü kurum ve kuruluşa hizmet vermekte olan ve bu alanlarda yeni fikir ve projelere öncülük eden bir markadır. </p>
                </div>
            </div>
        </div>
        <div className='bg-pengamum-black'>
            <p className='text-4xl font-bold text-pengamum-green text-center pt-10'>BİZE GÜVENEN MARKALAR.</p>
            <hr className='max-w-[200px] mx-auto border-4 border-pengamum-green mt-8' />
            <div className='grid md:grid-cols-4 grid-cols-2 gap-4 max-w-[1440px] md:mx-auto mx-4 py-10'>
                <img className='md:mx-auto' src={Pinbahis} alt="" />
                <img className='md:mx-auto' src={Asyabahis} alt="" />
                <img className='md:mx-auto' src={Sekabet} alt="" />
                <img className='md:mx-auto' src={Betnano} alt="" />
                <img className='md:mx-auto' src={Dumanbet} alt="" />
                <img className='md:mx-auto' src={Maltcasino} alt="" />
                <img className='md:mx-auto' src={Olabahis} alt="" />
                <img className='md:mx-auto' src={Xbet} alt="" />
            </div>
        </div>

        <Contact/>
    </>
  )
}
