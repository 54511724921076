import React from 'react'

import MABanner from '../images/mobile-app-banner.png'
import Image1 from '../images/ma-image1.png'
import Image2 from '../images/ma-image2.png'

import Green1 from '../images/green1.png'
import Green2 from '../images/green2.png'
import Green3 from '../images/green3.png'
import Green4 from '../images/green4.png'

import { Pricing } from '../components/Pricing'
import { OtherServices } from '../components/OtherServices'
import { Contact } from '../components/Contact'

export const MobileApp = () => {
  return (
    <>
        <div className='bg-pengamum-black py-16'>
            <div className='grid md:grid-cols-2 gap-6 items-center max-w-[1440px] mx-auto px-4'>
            <div className='max-w-[600px] mx-auto md:text-left text-center'>
                <p className='text-5xl text-white font-bold'>Mobil Uygulama</p>
                <p className='text-white py-4'>Pengamum mobile özel uygulama ve çözümleri ile yanınızda! Referanslarımız arasında yer alan çeşitli sektörlerden müşterilerimiz mobil uygulama ve mobile yönelik çözümlerimizden fazlasıyla memnun. Size özel bir çözüm sunmak için buradayız.</p>
                <a href="/contact-us"><div className='bg-pengamum-green hover:bg-[#297d3f] text-white hover:text-pengamum-green font-semibold p-2 text-center max-w-36 md:mx-0 mx-auto rounded-3xl'>
                    <p className='inline-block'>Teklif Al</p>
                </div></a>
            </div>
            <div>
                <img src={MABanner} alt="" />
            </div>
            </div>  
        </div>

        <div className='bg-pengamum-black py-16 px-4'>
            
            <div className='grid md:grid-cols-2 items-center md:text-left text-center'>
                <div>
                    <img className='mx-auto' src={Image1} alt="" />
                </div>
                <div className='max-w-[700px]'>
                    <p className='text-4xl text-white font-bold pb-4'>Projelerimize Güveniyor, Gizlilik ve Güvenliğe Önem Veriyoruz</p>
                    <p className='text-white'>
                        Partnerlerimiz ve müşterilerimiz için özel mobil uygulama çözümlerini en önemli önceliğimiz olan gizlilik ve güvenlirlik ilkeleri içerisinde, titizlikle geliştiriyoruz.
                        <br/><br/>
                        Veri güvenliği olmazsa olmazımız! Sürekli güncelleme ve bakımlar ile mobil platformunuzun güncel ve daima maksimum güvenlikte kalmasını sağlıyoruz. Gelişmiş feedback altyapımızla sorun ve diğer talepleriniz için hızlı bir şekilde teknik destek sunuyoruz.
                    </p>
                </div>
            </div>
        </div>

        <div className='bg-pengamum-black py-16 px-4'>
            <p className='text-4xl text-white text-center font-bold pb-4 max-w-[600px] mx-auto'>Mobil Uygulama ile Sağlayacağınız Bazı Avantajlar</p>
            <div className='grid md:grid-cols-3 pt-8 text-white'>
                <div className='md:text-right text-center'>
                    <div className='max-w-[500px] float-right'>
                        <img className='inline-flex' src={Green1} alt="" />
                        <p className='font-bold py-2'>Modern arayüzler</p>
                        <p>Mobil uygulamanız gelişmiş, modern, detaylı ve son derece kullanışlı bir arayüze sahip olur.</p>
                    </div>
                    <div className='max-w-[500px] float-right'>
                        <img className='inline-flex md:pt-36 pt-6' src={Green2} alt="" />
                        <p className='font-bold py-2'>Çözüm odaklı uygulama</p>
                        <p>Pengamum’da ihtiyaçlarınıza yönelik çözümler bulacaksınız. Bizimle amaçlarınızı paylaşmanız yeterli.</p>
                    </div>
                </div>
                <div>
                    <img className='mx-auto py-6' src={Image2} alt="" />
                </div>
                <div className='md:text-left text-center'>
                    <div className='max-w-[500px]'>
                        <img className='inline-flex' src={Green3} alt="" />
                        <p className='font-bold py-2'>Tam geliştirme</p>
                        <p>Mobil uygulama ve çözümlerinizi (UI) arayüz tasarımları, kullanıcı deneyimleri ve yazılım geliştirme dahil olarak servis ediyoruz.</p>
                    </div>
                    <div className='max-w-[500px]'>
                        <img className='inline-flex md:pt-36 pt-6' src={Green4} alt="" />
                        <p className='font-bold py-2'>Ücretsiz teknik destek</p>
                        <p>Sorun ve talepleriniz için ücretsiz teknik destek sağlıyoruz.</p>
                    </div>
                </div>
            </div>
        </div>

        <Pricing/>
        <OtherServices/>
        <Contact/>
    </>
  )
}
