import React from 'react'

import { Contact } from '../components/Contact'
import Carousel from "react-elastic-carousel";

import BannerImage from '../images/homepage-banner.png'

import Xbet from '../images/1xbet.svg'
import Pinbahis from '../images/pinbahis.svg'
import Asyabahis from '../images/asyabahis.svg'
import Sekabet from '../images/sekabet.svg'
import Betnano from '../images/betnano.svg'
import Dumanbet from '../images/dumanbet.svg'
import Maltcasino from '../images/maltcasino.svg'
import Olabahis from '../images/olabahis.svg'

import CategoryIco from '../images/category.svg'
import SettingIco from '../images/setting.svg'
import PanelIco from '../images/dev-panel.svg'
import CallIco from '../images/call.svg'

import Background from '../images/homepage-bg.png'
import Background2 from '../images/homepage-bg2.png'

import CalendarIco from '../images/Calendar.svg'
import ChatIco from '../images/Chat.svg'
import FolderIco from '../images/Folder.svg'
import NotificationIco from '../images/Notification.svg'
import PictureIco from '../images/Picture.svg'
import SettingsIco from '../images/Settings.svg'

import LiveImage from '../images/live-sport.png'
import StadIco from '../images/stadium.png'
import AutoIco from '../images/automatic.png'
import DigiIco from '../images/digital.png'
import MobileIco from '../images/mobile-app.png'

export const Homepage = () => {

  const slides = [
    {
      image: LiveImage,
      title: "Canlı Spor Yayınları",
      description: "Pengamum canlı spor yayınları platformu yazılımı ile özelleştirilebilir arayüz ve yayın desteğine sahip olun.",
    },
    {
      image: StadIco,
      title: "Stadyum Reklamları",
      description: "Pengamum canlı spor yayınları platformu yazılımı ile özelleştirilebilir arayüz ve yayın desteğine sahip olun.",
    },
    {
      image: AutoIco,
      title: "Otomatik Canlı Destek",
      description: "Web site ve uygulamalarınıza otomatik canlı destek ekleyin ve kullanıcı memnuniyetinizi artırın.",
    },
    {
      image: DigiIco,
      title: "Dijital Pazarlama & SEO Hizmeti",
      description: "Web site ve uygulamalarınıza otomatik canlı destek ekleyin ve kullanıcı memnuniyetinizi artırın.",
    },
    {
      image: MobileIco,
      title: "Mobil Uygulama",
      description: "Web site ve uygulamalarınıza otomatik canlı destek ekleyin ve kullanıcı memnuniyetinizi artırın.",
    },
  ];

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 }
  ];

  return (
    <>
      <div className='bg-pengamum-black py-16'>
        <div className='grid md:grid-cols-2 items-center max-w-[1440px] mx-auto'>
          <div className='max-w-[600px] mx-auto md:text-left text-center'>
            <p className='text-5xl text-pengamum-green font-bold'>Büyük Fikirleri Cebinize Sığdırıyoruz!</p>
            <p className='text-white py-4'>Sahte sitelerden yorulmadınız mı? En yeni teknikler ve profesyonel ekibimizle birlikte, kullanıcı odaklı mobil uygulama çözümlerimizi keşfedin!</p>
            <div className='bg-white hover:bg-[#297d3f] text-pengamum-black hover:text-white font-semibold p-2 text-center max-w-36 md:mx-0 mx-auto rounded-3xl'>
              <p className='inline-block'>İletişime Geç</p>
            </div>
          </div>
          <div>
            <img src={BannerImage} alt="" />
          </div>
        </div>  
      </div>

      <div className='bg-pengamum-black md:flex hidden items-center 2xl:justify-center px-4 overflow-x-auto gap-6 no-scrollbar py-12'>
        <img src={Xbet} alt="" />
        <img src={Pinbahis} alt="" />
        <img src={Asyabahis} alt="" />
        <img src={Sekabet} alt="" />
        <img src={Betnano} alt="" />
        <img src={Dumanbet} alt="" />
        <img src={Maltcasino} alt="" />
        <img src={Olabahis} alt="" />
      </div>

      <div className='bg-pengamum-black md:hidden block max-w-[1280px] mx-auto text-left py-10'>
      <Carousel breakPoints={breakPoints} enableAutoPlay={true} autoPlaySpeed={3000} showArrows={false} pagination={false} >
        <div>
          <img src={Xbet} alt="" />
        </div>
        
        <img src={Pinbahis} alt="" />
        <img src={Asyabahis} alt="" />
        <img src={Sekabet} alt="" />
        <img src={Betnano} alt="" />
        <img src={Dumanbet} alt="" />
        <img src={Maltcasino} alt="" />
        <img src={Olabahis} alt="" />
      </Carousel>
      </div>

      <div className='bg-pengamum-green text-pengamum-black text-center py-8'>
        <div className='max-w-[1440px] mx-auto'>
          <p className='font-bold'>İLGİNİZİ ÇEKEBİLECEK</p>
          <p className='text-4xl py-2 pb-4 font-bold'>Hazır Yazılım ve Modüllerimiz</p>
          <p>Websitemiz üzerinden hazır yazılımlarımızı sipariş geçerek hemen kullanıma başlayabilirsiniz. Eğer ki geliştiriciyseniz api hizmetlerimizi kendi yazılımlarınıza kolayca entegre edebilirsiniz.Daha önce iddaa tahmin yazılımlarımızı satın aldıysanız geliştirdiğimiz modülleri inceleyebilirsiniz.</p>
        </div>

        <div className='max-w-[1280px] mx-auto text-left py-10'>
          <Carousel breakPoints={breakPoints}>
            <div className='bg-white w-[280px] h-[340px] p-3 rounded-xl'> 
              <img src={LiveImage} alt="" />
              <p className='font-bold pt-2'>Canlı Spor Yayınları</p>
              <p className='py-1'>Pengamum canlı spor yayınları platformu yazılımı ile özelleştirilebilir arayüz ve yayın desteğine sahip olun.</p>
              <a className='inline-block' href="/broadcast"><div className='bg-pengamum-black hover:bg-[#297d3f] text-white hover:text-white font-semibold p-2 rounded-3xl pl-4 text-sm flex'>
                <p className='inline-block'>Detay Gör ▸</p>
              </div></a>
            </div>

            <div className='bg-white w-[280px] h-[340px] p-3 rounded-xl'> 
              <img src={StadIco} alt="" />
              <p className='font-bold pt-2'>Stadyum Reklamları</p>
              <p className='py-1'>Pengamum canlı spor yayınları platformu yazılımı ile özelleştirilebilir arayüz ve yayın desteğine sahip olun.</p>
              <a className='inline-block' href="/stadium"><div className='bg-pengamum-black hover:bg-[#297d3f] text-white hover:text-white font-semibold p-2 rounded-3xl pl-4 text-sm flex'>
                <p className='inline-block'>Detay Gör ▸</p>
              </div></a>
            </div>

            <div className='bg-white w-[280px] h-[340px] p-3 rounded-xl'> 
              <img src={AutoIco} alt="" />
              <p className='font-bold pt-2'>Otomatik Canlı Destek</p>
              <p className='py-1'>Web site ve uygulamalarınıza otomatik canlı destek ekleyin ve kullanıcı memnuniyetinizi artırın.</p>
              <a className='inline-block pt-6' href="/live-support"><div className='bg-pengamum-black hover:bg-[#297d3f] text-white hover:text-white font-semibold p-2 rounded-3xl pl-4 text-sm flex'>
                <p className='inline-block'>Detay Gör ▸</p>
              </div></a>
            </div>

            <div className='bg-white w-[280px] h-[340px] p-3 rounded-xl'> 
              <img src={DigiIco} alt="" />
              <p className='font-bold pt-2'>Dijital Pazarlama & SEO Hizmeti</p>
              <p className='py-1'>Web site ve uygulamalarınıza otomatik canlı destek ekleyin ve kullanıcı memnuniyetinizi artırın.</p>
              <a className='inline-block pt-6' href="/seo"><div className='bg-pengamum-black hover:bg-[#297d3f] text-white hover:text-white font-semibold p-2 rounded-3xl pl-4 text-sm flex'>
                <p className='inline-block'>Detay Gör ▸</p>
              </div></a>
            </div>

            <div className='bg-white w-[280px] h-[340px] p-3 rounded-xl'> 
              <img src={MobileIco} alt="" />
              <p className='font-bold pt-2'>Mobil Uygulama</p>
              <p className='py-1'>Web site ve uygulamalarınıza otomatik canlı destek ekleyin ve kullanıcı memnuniyetinizi artırın.</p>
              <a className='inline-block pt-6' href="/mobile-app"><div className='bg-pengamum-black hover:bg-[#297d3f] text-white hover:text-white font-semibold p-2 rounded-3xl pl-4 text-sm flex'>
                <p className='inline-block'>Detay Gör ▸</p>
              </div></a>
            </div>
          </Carousel>
        </div>
        
      </div>

      <div>
        <div className='grid md:grid-cols-4 gap-4 max-w-[1440px] mx-auto py-20'>
          <div className='md:flex items-center md:mx-0 mx-auto'>
            <img className='md:mx-0 mx-auto' src={CategoryIco} alt="" />
            <p className='text-pengamum-black font-bold pl-2'>Ücretsiz Kurulum</p>
          </div>
          <div className='md:flex items-center md:mx-0 mx-auto'>
            <img className='md:mx-0 mx-auto' src={SettingIco} alt="" />
            <p className='text-pengamum-black font-bold pl-2'>Ücretsiz Güncelleme</p>
          </div>
          <div className='md:flex items-center md:mx-0 mx-auto'>
            <img className='md:mx-0 mx-auto' src={PanelIco} alt="" />
            <p className='text-pengamum-black font-bold pl-2'>Profesyonel Panel</p>
          </div>
          <div className='md:flex items-center md:mx-0 mx-auto'>
            <img className='md:mx-0 mx-auto' src={CallIco} alt="" />
            <p className='text-pengamum-black font-bold pl-2'>7/24 Ücretsiz Destek</p>
          </div>
        </div>
      </div>

      <div className={"container-full lg:h-[847px] bg-black max-w-[1440px] mx-auto z-0 relative bg-no-repeat"} style={{ backgroundImage: `url("${Background}")` }}>
        <div className='grid lg:grid-cols-3 md:grid-cols-2 gap-8 text-white text-center py-20 md:mx-20 mx-4'>
          <div className='border-2 border-white rounded-2xl bg-gray-400 bg-opacity-40'>
            <img className='mx-auto max-w-48' src={SettingsIco} alt="" />
            <div className='pb-6 px-4'>
              <p className='font-bold text-xl pb-4'>Gelişmiş Altyapı</p>
              <p>Tüm yazılımlarımız en güncel alt yapıyı kullanmaktadır. Satın almış olduğunuz yazılımların tamamı framework uyumlu kod yapısına sahiptir.</p>
            </div>
          </div>
          <div className='border-2 border-white rounded-2xl bg-gray-400 bg-opacity-40'>
            <img className='mx-auto max-w-48' src={PictureIco} alt="" />
            <div className='pb-6 px-4'>
              <p className='font-bold text-xl pb-4'>Responsive Tasarım</p>
              <p>Yazılımlarımız tüm cihazlarda sorunsuz çalışacak şekilde dizayn edilmiştir.</p>
            </div>
          </div>
          <div className='border-2 border-white rounded-2xl bg-gray-400 bg-opacity-40'>
            <img className='mx-auto max-w-48' src={FolderIco} alt="" />
            <div className='pb-6 px-4'>
              <p className='font-bold text-xl pb-4'>Yönetim Paneli</p>
              <p>Gelişmiş yönetim paneli sayesinde yazılımlarımızın her noktasına anında müdahale edebilirsiniz.</p>
            </div>
          </div>

          <div className='border-2 border-white rounded-2xl bg-gray-400 bg-opacity-40'>
            <img className='mx-auto max-w-48' src={CalendarIco} alt="" />
            <div className='pb-6 px-4'>
              <p className='font-bold text-xl pb-4'>Sürekli Güncelleme</p>
              <p>Yazılımlarımız her geçen gün gelişmektedir.Bazı güncellemeler ücretli bazıları ise ücretsiz tüm kullanıcılarımıza sunulmaktadır.</p>
            </div>
          </div>
          <div className='border-2 border-white rounded-2xl bg-gray-400 bg-opacity-40'>
            <img className='mx-auto max-w-48' src={NotificationIco} alt="" />
            <div className='pb-6 px-4'>
              <p className='font-bold text-xl pb-4'>Yeni Yazılımlar</p>
              <p>Müşterilerimizin istekleri doğrultusunda yeni yazılım ve yeni modüller devamlı sitemize eklenmektedir.</p>
            </div>
          </div>
          <div className='border-2 border-white rounded-2xl bg-gray-400 bg-opacity-40'>
            <img className='mx-auto max-w-48' src={ChatIco} alt="" />
            <div className='pb-6 px-4'>
              <p className='font-bold text-xl pb-4'>Ücretsiz Teknik Destek</p>
              <p>Teknik destek tüm yazılımlarımız için ücretsiz olup 7/24 bizimle iletişime geçebilirsiniz.</p>
            </div>
          </div>
        </div>
      </div>

      <div className={"container-full md:h-[558px] bg-[#1a1428] pb-4 max-w-[1440px] mx-auto z-0 relative bg-no-repeat"} style={{ backgroundImage: `url("${Background2}")` }}>
        <div className='text-white md:mx-20 mx-4 pt-12 md:text-left text-center'>
          <div className='max-w-[500px]'>
            <p className='font-bold text-5xl leading-tight pb-4'><span className='text-pengamum-green'>Pengamum</span> Çözümlerinin Getirileri</p>
            <p>Yenilikçi yaklaşımlarımızla SEO, stadyum reklamları, canlı spor yayınları ve mobil uygulama alanlarındaki başarımızı sizlerle paylaşmaktan gurur duyuyoruz.</p>
          </div>
          <div className='grid md:grid-cols-3 pt-8'>
            <div>
              <p className='text-pengamum-green font-bold text-5xl pb-4'>3000+</p>
              <p>SEO, mobil uygulama, stadyum reklamları, dijital pazarlama gibi alanlarda ürettiğimiz çözümler.</p>
            </div>
            <div>
              <p className='text-pengamum-green font-bold text-5xl pb-4'>1 ay+</p>
              <p>Tüm müşteriler genelinde organik görüntülenmelerdeki artış.</p>
            </div>
            <div>
              <p className='text-pengamum-green font-bold text-5xl pb-4'>5.4x</p>
              <p>Tüm müşteriler için ortalam SEO harcamalarının getirisi.</p>
            </div>
          </div>
        </div>
      </div>

      <Contact/>
    </>
  )
}
