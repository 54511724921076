import React from 'react'
import Logo from '../images/footer-logo.svg'

// import XIcon from '../images/twitter.svg'
// import IGIcon from '../images/instagram.svg'
// import FBIcon from '../images/facebook.svg'
// import YTIcon from '../images/youtube.svg'

export const Footer = () => {
  return (
    <div className='bottom-0 w-full sm:pt-10 md:pt-48 pt-60 py-10 px-4'>
      <div className='grid md:grid-cols-5 max-w-[1440px] mx-auto'>
        <div>
          <img className='md:mx-0 mx-auto' src={Logo} alt="" />
          <p className='text-sm pt-4 md:text-left text-center md:text-base text-sm'>Tüm Hakları Saklıdır | Pengamum 2023</p>
        </div>
        <div className='flex gap-8 mx-auto md:col-span-3 md:py-0 py-4 md:text-base text-xs'>
          <a href="#"><p>Hakkımızda</p></a>
          <a href="#"><p>Gizlilik  sözleşmesi</p></a>
          <a href="#"><p>İade şartları</p></a>
        </div>
      </div>
    </div>
  )
}
