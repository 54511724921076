import React from 'react'

import SeoBanner from '../images/seo-banner.png'

import { Contact } from '../components/Contact'
import { OtherServices } from '../components/OtherServices'
import { Pricing } from '../components/Pricing'

import Tick from '../images/green-tick.svg'

export const Seo = () => {
  return (
    <>
        <div className='bg-pengamum-black py-16'>
            <div className='grid md:grid-cols-2 max-w-[1440px] mx-auto items-center px-4'>
            <div className='max-w-[600px] mx-auto md:text-left text-center'>
                <p className='text-5xl text-pengamum-green font-bold'>SEO & Dijital Pazarlama Hizmeti</p>
                <p className='text-white py-4'>Günümüz iş dünyasında, başarılı bir dijital varlık oluşturmak ve online görünürlüğü artırmak işletmelerin sıradanlıktan sıyrılmasını sağlar. Pengamum olarak bu noktada profesyonel dijital pazarlama ve SEO hizmetlerimizle, markanızı dijital dünyada güçlendirmek ve rakiplerinizin önüne geçmek için buradayız.</p>
                <a href="/contact-us"><div className='bg-pengamum-green hover:bg-[#297d3f] text-white hover:text-pengamum-green font-semibold p-2 text-center max-w-36 md:mx-0 mx-auto rounded-3xl'>
                    <p className='inline-block'>Teklif Al</p>
                </div></a>
            </div>
            <div>
                <img className='mx-auto' src={SeoBanner} alt="" />
            </div>
            </div>  
        </div>
        <div className='bg-pengamum-black text-white py-10 px-4'>
            <div className='max-w-[1440px] mx-auto'>
                <p className='text-pengamum-green text-center font-bold text-lg'>NEDEN SEO?</p>
                <p className='text-center font-bold text-2xl pb-2'>Keşfedilmeyi Beklemeyin, Hedef Kitlenize Ulaşın</p>
                <p className='md:text-left text-center'>Arama motorlarında üst sıralarda yer almak, online başarının anahtarıdır. SEO uzmanlarımız, web sitenizin organik arama sonuçlarında en üst sıralarda yer almasını sağlamak için güçlü bir strateji oluşturur. Anahtar kelime optimizasyonu, teknik SEO, içerik yönetimi ve backlink stratejileri gibi alanlarda uzmanlık sunarak, markanızın görünürlüğünü artırır ve hedef kitlenizin dikkatini çekeriz.</p>
                <br/>
                <p className='md:text-left text-center'>Müşterilerinizle daha etkileşimli ve kişiselleştirilmiş bir diyalog kurmanıza yardımcı olmak için kapsamlı dijital pazarlama stratejileri sunuyoruz. Sosyal medya yönetimi, e-posta pazarlaması, içerik stratejileri ve diğer dijital kanallar aracılığıyla marka bilinirliğinizi artırmak ve hedef kitlenizle bağlantı kurmak için uzman ekibimizle birlikte çalışıyoruz.</p>
            </div>
        </div>
        <div className='bg-pengamum-gray py-8'>
            <div className='grid md:grid-cols-3 gap-4 max-w-[1440px] mx-auto'>
                <div>
                    <p className='text-center font-bold text-white md:text-3xl text-xl'>20+ SEKTÖR DENEYİMİ</p>
                </div>
                <div>
                    <p className='text-center font-bold text-white md:text-3xl text-xl'>20+ YILLIK DENEYİMLİ KADRO</p>
                </div>
                <div>
                    <p className='text-center font-bold text-white md:text-3xl text-xl'>KİŞİSELLEŞTİRİLMİŞ ÇÖZÜMLER</p>
                </div>
            </div>
        </div>
        <div className='bg-pengamum-black text-white py-16 md:text-left text-center'>
            <div className='max-w-[1440px] mx-auto grid md:grid-cols-2 px-4'>
                <div>
                    <p className='text-pengamum-green font-bold py-2 text-lg'>ÖZELLİKLER</p>
                    <p className='font-bold text-2xl pb-2'>Dijital Pazarlama ve SEO ile Sağladığımız Bazı Hizmetler</p>
                    <p>Dijital ortamda bulunamıyor ya da görünmüyor olmanızın nedeni Dijital dünyanın genişlemesidir. Günden güne artan web siteler ve reklam alanları internette ön plana çıkmanızı, ayırt edilmenizi engeller ve işletmesini internete taşıyan kişiler ya da kurumlar için işleri oldukça karmaşık hale getirir. İşte Pengamum’un sizi ön plana çıkarmak için uyguladığı bazı hizmet ve stratejiler!</p>
                </div>
                <div className='text-left pt-4 md:pl-0 pl-4'>
                    <div className='flex pb-3'>
                        <img className='pr-2' src={Tick} alt="" />
                        <p>Yapay zeka destekli strateji önerileri</p>
                    </div>
                    <div className='flex pb-3'>
                        <img className='pr-2'  src={Tick} alt="" />
                        <p>Rakip analizi ve potansiyel kitle izleme</p>
                    </div>
                    <div className='flex pb-3'>
                        <img className='pr-2'  src={Tick} alt="" />
                        <p>Veri destekli optimizasyon fikirleri</p>
                    </div>
                    <div className='flex pb-3'>
                        <img className='pr-2'  src={Tick} alt="" />
                        <p>Deneyimli editörler tarafından hazırlanan görüntüleme artırıcı metinler</p>
                    </div>
                    <div className='flex pb-3'>
                        <img className='pr-2'  src={Tick} alt="" />
                        <p>SEO getiri raporlaması ve veri analizi</p>
                    </div>
                    <div className='flex'>
                        <img className='pr-2'  src={Tick} alt="" />
                        <p>Sayfa içi, sayfa dışı teknik SEO</p>
                    </div>
                </div>
            </div>
        </div>

        <Pricing/>
        <OtherServices/>
        <Contact/>
    </>
  )
}
