import React from 'react'

import ContactBanner from '../images/contactus-banner.png'

import Frame1 from '../images/frame1.svg'
import Frame2 from '../images/frame2.svg'
import Frame3 from '../images/frame3.svg'

import WP from '../images/whatsapp-icon.svg'
import Skype from '../images/skype-icon.svg'
import ContactForm2 from '../components/ContactForm2'

export const ContactUs = () => {
  return (
    <>
        <div className={"container-full md:h-[734px] bg-pengamum-black md:pb-0 pb-20  bg-no-repeat"} style={{ backgroundImage: `url("${ContactBanner}")` }}>
            <div className='grid md:grid-cols-2 items-center pt-20 text-center px-2'>
                <div className='max-w-[500px] mx-auto text-center'>
                    <p className='text-pengamum-green fomt-semibold'>BİZE ULAŞIN</p>
                    <p className='text-5xl text-white font-bold'>Zirveye Giden Yolda Son 1 Adım!</p>
                    <p className='text-white py-4'>Kariyerinizde zirveye ulaşmak için dijitalin gücünden Pengamum ile yararlanın. </p>
                    <div className='grid md:grid-cols-3 gap-4'>
                        <img className='max-h-[154px] md:mx-0 mx-auto max-w-[180px]' src={Frame1} alt="" />
                        <img className='max-h-[154px] md:mx-0 mx-auto max-w-[180px]' src={Frame2} alt="" />
                        <img className='max-h-[154px] md:mx-0 mx-auto max-w-[180px]' src={Frame3} alt="" />
                    </div>
                    <div className='py-4'>
                        <div className='text-center pb-6'>
                            <img className='mx-auto' src={Skype} alt="" />
                            <p className='text-white'>info@pengamum.com</p>
                        </div>
                        <div className='md:col-span-3 text-left text-white pb-4'>
                            <p>Bize sosyal medya adreslerimiz üzerinden ve WhatsApp hattımızdan ulaşabilirsiniz.</p>
                        </div>
                    </div>
                </div>
                <ContactForm2 />
            </div> 
        </div>
    </>
  )
}
