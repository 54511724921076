import React from 'react'

import { Outlet } from "react-router-dom"
import { Navbar } from './Navbar'
import { Footer } from './Footer'
import WPIcon from '../images/wp-icon.png'

export const LayoutWebsite = () => {
  return (
    <>
        <Navbar/>
        <div id='main'>
            <Outlet />
        </div>
        <Footer/>
        <a target='_blank' href="https://api.whatsapp.com/send?phone=447727899550"><div className='absolute bottom-2 float-right sticky'>
          <img className='max-w-[70px]' src={WPIcon} alt="" />
        </div></a>
    </>
  )
}
