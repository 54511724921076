import { useState } from 'react'
import { useForm } from "react-hook-form"
import axios from 'axios'
import Popup from 'reactjs-popup';
export const Contact = () => {
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm()


  const onSubmit = async (data) => {
    console.log(data)
    try {
      setOpen(true)
      const result = await axios.post('https://btbas1qhnk.execute-api.eu-central-1.amazonaws.com/v1/form', {
        subject: "Needs Form",
        data: data
      });
      console.log(result)
      reset()
      setTimeout(() => {
        setOpen(false)
      }, 2500);
    } catch (error) {
      setTimeout(() => {
        setOpen(false)
    }, 2500);
      console.log(error)
    }
  }

  return (
    <>
      <div className='max-w-[1440px] mx-auto bg-[#F4F4F4]'>
        <div className='grid md:grid-cols-2 h-[490px] items-center md:mx-20 mx-4 py-8'>
          <div className='py-4'>
            <p className='font-bold text-3xl border-l-4 border-pengamum-green text-pengamum-green pl-2 mb-4'>Markanızı Büyütmeye Hazır mısınız?</p>
            <p className='font-bold text-5xl text-pengamum-black max-w-[550px]'>Birlikte Çalışmaya Bugün Başlayalım!</p>
          </div>
          <form>
            <input
              {...register("name", { required: true })}
              class="shadow appearance-none border rounded-2xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
              id="name"
              type="text"
              placeholder="İsim, soyisim"
            />
            <input
              {...register("email", { required: true })}
              class="shadow appearance-none border rounded-2xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
              id="mail" type="text"
              placeholder="Email" />
            <input
              {...register("budget", { required: true })}
              class="shadow appearance-none border rounded-2xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
              id="budget"
              type="text"
              placeholder="Tahmini bütçeniz" />
            <textarea
              {...register("need", { required: true })}
              class="shadow appearance-none border rounded-2xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4 py-4"
              id="need"
              type="text"
              cols="40"
              rows="5"
              maxLength={250}
              placeholder="Neye ihtiyacınız var?" />
            <button
              type='button'
              onClick={handleSubmit(onSubmit)}
              className='bg-pengamum-black text-white rounded-2xl px-12 py-2'>
              Gönder
            </button>
          </form>
        </div>
        <Popup open={open} modal contentStyle={{
          width: 'auto',
          padding: '0px',
          borderWidth: '0px',
          borderRadius: '9px',
        }}>
          <div className="relative w-full max-w-md h-full md:h-auto">
            <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              <button
                onClick={() => setOpen(false)}
                type="button"
                className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                <svg aria-hidden="true" className="w-8 h-8 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                <span className="sr-only">Success</span>
              </div>
              <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">Formunuz Başarı ile Gönderilmiştir!</p>
            </div>
          </div>
        </Popup>
      </div>
    </>
  )
}