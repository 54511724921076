import React from 'react'

import Banner from '../images/broadcast-banner.png'

import { Pricing } from '../components/Pricing'
import { OtherServices } from '../components/OtherServices'
import { Contact } from '../components/Contact'

import Image1 from '../images/tennis-img.png'
import Image2 from '../images/volleyball-img.png'
import Image3 from '../images/espor-img.png'
import Image4 from '../images/tabletennis-img.png'
import Image5 from '../images/basketball-img.png'
import Image6 from '../images/football-img.png'

import Broadcast1 from '../images/broadcast-img.png'
import Broadcast2 from '../images/broadcast-img2.png'
import Broadcast3 from '../images/broadcast-img3.png'
import Broadcast4 from '../images/broadcast-img4.png'

import BroadVolley from '../images/broadcast-v.svg'
import BroadFoot from '../images/broadcast-f.svg'
import BroadBasket from '../images/broadcast-b.svg'
import BroadPredict from '../images/broadcast-p.svg'

import Tick from '../images/green-tick.svg'

export const Broadcast = () => {
  return (
    <>
        <div className='bg-pengamum-black py-16'>
            <div className='grid md:grid-cols-2 max-w-[1440px] mx-auto gap-6 items-center px-4'>
            <div className='max-w-[600px] mx-auto md:text-left text-center'>
                <p className='text-5xl text-white font-bold'>Canlı Spor Yayınları</p>
                <p className='text-white py-4'>PENGAMUM canlı spor yayınları platformu yazılımı, Uluslararası, Avrupa, Amerika, Afrika ve Asya bayi tabanlı operatörlere hizmet verir.</p>
                <a href="/contact-us"><div className='bg-pengamum-green hover:bg-[#297d3f] text-white hover:text-pengamum-green font-semibold p-2 text-center max-w-36 md:mx-0 mx-auto rounded-3xl'>
                    <p className='inline-block'>Teklif Al</p>
                </div></a>
            </div>
            <div>
                <img src={Banner} alt="" />
            </div>
            </div>  
        </div>

        <div className='bg-pengamum-black text-white py-10 px-4'>
            <div className='max-w-[1440px] mx-auto'>
                <p className='text-pengamum-green text-center font-bold text-lg'>API ve API SİSTEMLERİMİZ</p>
                <p className='text-center font-bold text-2xl py-4'>Canlı Spor Yayınları API’lerimiz ve API Sistemlerimiz</p>
                <p className='md:text-left text-center'>Pengamum canlı spor yayınları platformu yazılımı, Uluslararası, Avrupa, Amerika, Afrika ve Asya bayi tabanlı operatörlere hizmet verir. Halihazırda mevcut bir platform/start-up ile veya çevrimiçi casino operatörü ya da yerleşik bahis merkezi bayi olmanızın önemi olmaksızın PENGAMUM’UN API’lerini kullanarak kolayca entegre edilebilen kapsamlı çok dilli, özelleştirilebilir arayüz ve yayın desteğine sahip olabilirsiniz..</p>
            </div>
            <div className='grid md:grid-cols-3 gap-4 max-w-[1580px] pt-8 mx-auto'>
                <div>
                    <img className='mx-auto' src={Image1} alt="" />
                </div>
                <div>
                    <img className='mx-auto' src={Image2} alt="" />
                </div>
                <div>
                    <img className='mx-auto' src={Image3} alt="" />
                </div>
                <div>
                    <img className='mx-auto' src={Image4} alt="" />
                </div>
                <div>
                    <img className='mx-auto' src={Image5} alt="" />
                </div>
                <div>
                    <img className='mx-auto' src={Image6} alt="" />
                </div>
            </div>
        </div>

        <div className='bg-pengamum-black py-16 px-4'>
            <div className='grid md:grid-cols-2 gap-4 max-w-[1440px] mx-auto text-white'>
                <div>
                    <p className='text-pengamum-green md:text-left text-center font-bold text-lg'>ÖZELLİKLER</p>
                    <p className='md:text-left text-center font-bold text-4xl py-4'>Yayın API’lerimiz ile Elde Edeceğiniz Avantajlar</p>
                    <p className='md:text-left text-center'>Pengamum Stream API’leri ile size her zaman kaliteli, canlı maç yayını sözü verir. Müşterileriniz karşılaşmaları her zaman en yüksek kalitede sorunsuzca izler.</p>
                </div>
                <div>
                    <img className='mx-auto' src={Broadcast1} alt="" />
                </div>
            </div>

            <div className='max-w-[1440px] mx-auto text-white pt-16'>
                <p className='text-center font-bold text-4xl'>Yayın API’lerimizin Özellikleri</p>
                <div className='grid md:grid-cols-3 gap-8 text-black py-10 mx-4'>
                    <div className='bg-white rounded-xl p-4'>
                        <img className='mx-auto' src={Tick} alt="" />
                        <p className='text-center text-xl pt-3'>21+ Kategoride Spor Dalı</p>
                    </div>
                    <div className='bg-white rounded-xl p-4'>
                        <img className='mx-auto' src={Tick} alt="" />
                        <p className='text-center text-xl pt-3'>7/24 Premium Yayınlar</p>
                    </div>
                    <div className='bg-white rounded-xl p-4'>
                        <img className='mx-auto' src={Tick} alt="" />
                        <p className='text-center text-xl pt-3'>İsteğe Özel Kanallar</p>
                    </div>
                </div>
                <p className='text-center font-bold text-4xl pb-4'>Anahtar Teslim Çözümler</p>
                <p className='md:text-left text-center'>Size Özel API’miz, canlı spor yayınları platformu entegrasyonu sağlamanın yanı sıra, İsteğe özel premium kanalları da sizin için sisteme dahil eder ve bunu sizin için sağlar. CRM sistemi ve 7/24 müşteri desteğiyle, platformumuz ve API çözümlerimiz, müşterilerimize hızlı pazar girişi için ihtiyaçları olan her şeyi sağlar.
                <br/><br/>
                Anahtar Teslim çözümümüz 100’ün üzerinde spor, 70.000’in üzerinde Gerçek Canlı Aylık Etkinlik, 15.000’ün üzerinde lig ve 3.000’in üzerinde Bahis Piyasası için 700’den fazla kurum içi risk yöneticisi ve spor yatırımcısını içeren tescilli Canlı Spor Yayınları APİ’Lerimizi sizlere sunuyor.</p>
            </div>

            <div className='grid md:grid-cols-2 max-w-[1440px] mx-auto text-white pt-16'>
                <div>
                    <img className='mx-auto' src={Broadcast2} alt="" />
                </div>
                <div>
                    <p className='md:text-left text-center font-bold text-4xl py-4'>Banner Entegrasyon</p>
                    <p className='md:text-left text-center'>Banner entegrasyon çözümleri ile web sayfalarınızdaki reklamları kolayca yönetin. Web sayfalarınızda yer alan banner reklamlarının yerlerini değiştirebilir, yeni reklamlarını otomatik olarak güncelleyebilirsiniz.</p>
                </div>
            </div>
        </div>

        <div className='bg-pengamum-gray px-4'>
            <div className='grid md:grid-cols-2 gap-4 max-w-[1440px] mx-auto text-white py-16'>
                <div className='grid grid-cols-2 gap-4 max-w-[1000px] mx-auto'>
                    <div>
                        <img src={BroadPredict} alt="" />
                    </div>
                    <div>
                        <img src={BroadFoot} alt="" />
                    </div>
                    <div>
                        <img src={BroadBasket} alt="" />
                    </div>
                    <div>
                        <img src={BroadVolley} alt="" />
                    </div>
                </div>
                <div>
                    <img className='mx-auto' src={Broadcast3} alt="" />
                </div>
            </div>
        </div>

        <div className='bg-pengamum-black'>
            <div className='grid md:grid-cols-2 max-w-[1440px] items-center mx-auto text-white py-16'>
                <div>
                    <img className='mx-auto' src={Broadcast4} alt="" />
                </div>
                <div>
                    <p className='md:text-left text-center font-bold text-4xl py-4'>Güncel Oranlar</p>
                    <p className='md:text-left text-center pb-4'>Sitenizden oranları dilediğiniz maçın detayına koyabilirsiniz. Betradar ve LiveScore sponsorluğunda ücretsiz oranları yansıtabilir ve oranlar üzerinde değişiklik yapabilirsiniz.</p>
                    <a href="/contact-us"><div className='bg-pengamum-green hover:bg-[#297d3f] text-white hover:text-pengamum-green font-semibold p-2 text-center max-w-36 md:mx-0 mx-auto rounded-3xl'>
                    <p className='inline-block'>Teklif Al</p>
                    </div></a>
                </div>
            </div>
        </div>
    
        <Pricing/>
        <OtherServices/>
        <Contact/>
    </>
  )
}
