import React from 'react'

import SeoIco from '../images/seo-icon.svg'
import Image1 from '../images/seo-img1.png'
import Image2 from '../images/seo-img2.png'
import Image3 from '../images/seo-img3.png'
import Icon1 from '../images/icon1.svg'
import Icon2 from '../images/icon2.svg'
import Icon3 from '../images/icon3.svg'

export const Pricing = () => {
  return (
    <>
        <div>
            <div className='bg-gradient-to-r from-[#E9FFE9] md:h-[1280px] to-[#D6E3D5] py-10'>
                <img className='mx-auto' src={SeoIco} alt="" />
                <p className='text-pengamum-green font-bold py-2 text-lg text-center'>FİYATLANDIRMA</p>
                <p className='text-pengamum-black font-semibold text-2xl text-center'>Size Özel Fiyatlandırmayı <br/>Nasıl Belirliyoruz?</p>

                <div className='grid md:grid-cols-5 gap-4 md:text-left text-center items-center py-20 max-w-[1440px] mx-auto'>
                    <div className='md:col-span-2'>
                        <img className='mx-auto' src={Image1} alt="" />
                    </div>
                    <div>
                        <img className='mx-auto' src={Icon1} alt="" />
                    </div>
                    <div className='md:col-span-2 max-w-[400px] mx-auto'>
                        <p className='font-bold'>Hedefleriniz Neler?</p>
                        <p>Hedeflerinize yönelik bir SEO planı oluşturmak için kısa ve uzun vadede neyi hedeflediğinizi öğreneceğiz.</p>
                    </div>
                    <div className='md:hidden block'>
                        <img className='mx-auto' src={Icon2} alt="" />
                    </div>
                    <div className='md:col-span-2 max-w-[400px] mx-auto'>
                        <p className='font-bold md:text-right text-center'>Pazar Analizi</p>
                        <p className='md:text-right text-center'>En iyi hareket planı için mevcut rakiplerinizi ve pazarınızı derinlemesine inceleyeceğiz.</p>
                    </div>
                    <div className='md:block hidden'>
                        <img className='mx-auto' src={Icon2} alt="" />
                    </div>
                    <div className='md:col-span-2'>
                        <img className='mx-auto' src={Image2} alt="" />
                    </div>

                    <div className='md:col-span-2'>
                        <img className='mx-auto' src={Image3} alt="" />
                    </div>
                    <div>
                        <img className='mx-auto' src={Icon3} alt="" />
                    </div>
                    <div className='md:col-span-2 max-w-[400px] mx-auto'>
                        <p className='font-bold'>Stratejik Hedeflerinizi Gözden Geçirin</p>
                        <p>İçerik üretimini mi artırmak istiyorsunuz? Yeni bir kampanya ya da ürün serisi mi başlatacaksınız? Amacınıza uygun planı sağlayacağız.</p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
