import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { LayoutWebsite } from './components/LayoutWebsite';
import { Homepage } from './pages/Homepage';
import { About } from './pages/About';
import { ContactUs } from './pages/ContactUs';
import { Seo } from './pages/Seo';
import { MobileApp } from './pages/MobileApp';
import { Broadcast } from './pages/Broadcast';
import { LiveSupport } from './pages/LiveSupport';
import { Stadium } from './pages/Stadium';
import { Draw } from './pages/Draw';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route element={<LayoutWebsite />}>
            <Route path="/" element={<Homepage />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/seo" element={<Seo />} />
            <Route path="/mobile-app" element={<MobileApp />} />
            <Route path="/broadcast" element={<Broadcast />} />
            <Route path="/live-support" element={<LiveSupport />} />
            <Route path="/stadium" element={<Stadium />} />
            <Route path="/draw" element={<Draw />} />
          </Route>
        </Routes>
    </BrowserRouter>
  )
}

export default App;
