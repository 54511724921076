import React,{useState} from 'react'
import Logo from '../images/logo.svg'
import LangLogo from '../images/lang-logo.svg'
import BurgerMenu from '../images/burger-menu.png'
import CloseBurgerMenu from '../images/close-burger-menu.png'

export const Navbar = () => {

  const [burger, setBurger] = useState('closed'); 
  const [isClicked, setIsClicked] = useState('closed');
  const [isLangOpen, setIsLangOpen] = useState('closed');
  const [whichLang, setWhichLang] = useState('Türkçe');

  const openHide = () => {
    if(isClicked == 'closed'){
      setIsClicked('opened')
    }
    else{
      setIsClicked('closed')
    }
  }

  const langOpenHide = () => {
    if(isLangOpen == 'closed'){
      setIsLangOpen('opened')
    }
    else{
      setIsLangOpen('closed')
    }
  }

  const langChanger = () => {
    if(whichLang == 'Türkçe'){
      setWhichLang('English')
    }
    else{
      setWhichLang('Türkçe')
    }
  }

  return (
    <>
      <div className='bg-pengamum-black w-full'>
        <div className='h-[75px] flex items-center max-w-[1440px] mx-auto text-white p-4 top-0'>
          <div className='flex'>
            <a href="/"><img src={Logo} alt="" /></a>
          </div>
          <div className='md:flex hidden gap-8 float-right mx-auto'>
            <a href="/"><p>Anasayfa</p></a>
            <div className='relative'>
              <a onClick={openHide} href="#"><p>Çözümlerimiz <span className='text-xs'>▼</span></p></a>
              <div className={`${isClicked == 'closed' ? 'hidden' : 'block'} bg-white text-black font-semibold rounded-lg absolute left-[-86px] top-10 w-72 p-2 text-center`}>
                <a className='block pb-1 hover:bg-pengamum-green rounded-lg px-2' href="/stadium">Stadyum Reklamları</a>
                <a className='block pb-1 hover:bg-pengamum-green rounded-lg px-2' href="/broadcast">Canlı Spor Yayınları</a>
                <a className='block pb-1 hover:bg-pengamum-green rounded-lg px-2' href="/live-support">Otomatik Canlı Destek Hizmeti</a>
                <a className='block pb-1 hover:bg-pengamum-green rounded-lg px-2' href="/seo">Dijital Pazarlama ve SEO</a>
                <a className='block pb-1 hover:bg-pengamum-green rounded-lg px-2' href="/mobile-app">Mobil Uygulama</a>
                <a className='block hover:bg-pengamum-green rounded-lg px-2' href="/draw">Çekiliş Sistemi</a>
              </div>
            </div>
              
            <a href="/about"><p>Hakkımızda</p></a>
          </div>
          
          <div className='relative'>
            <a onClick={langOpenHide} className='md:block hidden border-2 border-white p-2 rounded-3xl pl-6 flex'>
                <img className='inline-block pr-1' src={LangLogo} alt="" />
                <p className='inline-block w-20'>{whichLang} <span className='text-xs'>▼</span></p>
            </a>
            <div className={`${isLangOpen == 'closed' ? 'hidden' : 'block'} bg-white text-black font-semibold rounded-lg absolute top-16 w-36 p-2 text-center`}>
                <a onClick={langChanger} className='block pb-1 hover:bg-pengamum-green rounded-lg px-2'>Türkçe</a>
                <a onClick={langChanger} className='block pb-1 hover:bg-pengamum-green rounded-lg px-2'>English</a>
            </div>
          </div>
          
          <a className='md:block hidden' href="/contact-us"><div className='bg-pengamum-green hover:bg-[#297d3f] text-pengamum-black hover:text-white ml-4 font-semibold p-2 rounded-3xl pl-4 flex'>
              <p className='inline-block w-28'>İletişime Geç ▸</p>
          </div></a>
          <div className='flex xs:ml-72 ml-48 w-12 md:hidden block'>
              <button className=' z-40' onClick={(e) => burger == 'closed' ? setBurger('opened') : setBurger('closed')}>
                  <img src={burger == 'closed' ? BurgerMenu : CloseBurgerMenu} alt="" />
              </button>
          </div>
        </div>
      </div>
      <div className={`menu-gradient bg-pengamum-black h-screen w-full pt-24 px-10 fixed top-0 z-10 text-white ${burger == 'closed' ? 'hidden' : 'block'}`}>
        <a href="/"><p className='pl-4'>Anasayfa</p></a>
        <hr className='border-white max-w-[300px] md:hidden block mt-2 py-2' />
        <a href="/about"><p className='pl-4'>Hakkımızda</p></a>
        <hr className='border-white max-w-[300px] md:hidden block mt-2 py-2' />
        <a href="/stadium"><p className='pl-4'>Stadyum Reklamları</p></a>
        <hr className='border-white max-w-[300px] md:hidden block mt-2 py-2' />
        <a href="/broadcast"><p className='pl-4'>Canlı Spor Yayınları</p></a>
        <hr className='border-white max-w-[300px] md:hidden block mt-2 py-2' />
        <a href="/live-support"><p className='pl-4'>Otomatik Canlı Destek Hizmeti</p></a>
        <hr className='border-white max-w-[300px] md:hidden block mt-2 py-2' />
        <a href="/seo"><p className='pl-4'>Dijital Pazarlama ve SEO</p></a>
        <hr className='border-white max-w-[300px] md:hidden block mt-2 py-2' />
        <a href="/mobile-app"><p className='pl-4'>Mobil Uygulama</p></a>
        <hr className='border-white max-w-[300px] md:hidden block mt-2 py-2' />
        <a href="/draw"><p className='pl-4'>Çekiliş Sistemi</p></a>
        <hr className='border-white max-w-[300px] md:hidden block mt-2 py-2' />
        <a href="/contact-us"><p className='pl-4'>İletişim</p></a>
      </div>
    </>
  )
}
